<template>
    <transition
        appear
        name="fade"
        mode="out-in"
    >
        <div
            v-if="visible"
            class="container"
        >
            <div class="background-image" :style="{ 'background-image': `url(${image})` }"/>
            <div class="box" :class="position">
                <h1>{{ title }}</h1>
                <p>{{ text }}</p>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { Options, Vue } from 'vue-class-component'

export enum EPosition {
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right'
}
export type Position = EPosition
export interface Content {
    title?: string,
    text?: string,
    position?: EPosition
}

// eslint-disable-next-line no-use-before-define
@Options<ImageDisplayer>({
  props: {
    index: Number,
    current: Number,
    image: String,
    content: Array as PropType<Content[]>
  },
  watch: {
    current (current: number) {
      this.visible = current === this.index
      if (this.visible) this.selected = Math.round(Math.random() * (this.content.length - 1))
    },
    content () {
      this.selected = Math.round(Math.random() * (this.content.length - 1))
    }
  }
})
export default class ImageDisplayer extends Vue {
    visible = false
    index!: number

    image = null
    content: Content[] = []

    selected = 0
    get title (): string {
      return this.content[this.selected]?.title || ''
    }

    get text (): string {
      return this.content[this.selected]?.text || ''
    }

    get position (): Position {
      return this.content[this.selected]?.position || EPosition.TOP_LEFT
    }
}
</script>

<style scoped lang="scss">
h1:first-child {
    margin-top: 0;
    font-size: 34px;
}
p:last-child {
    margin-bottom: 0;
    font-size: 24px;
    opacity: 0.8;
}

.container {
    position: fixed;
    height: 100%;
    width: 100%;
}
.background-image {
    z-index: -9001;
    position: fixed;
    height: 100%;
    width: 100%;
    background: no-repeat center fixed;
    background-size: cover;
    filter: saturate(0.65) brightness(0.8);
}
.box {
    position: absolute;
    margin: 20px;
    padding: 20px !important;
    width: min-content min-content;
    max-width: 700px;
}

// Positions
.top-left, .top-right {
    top: 0;
}
.bottom-left {
    bottom: 0;
}
.bottom-right {
    bottom: 100px;
}
.top-left, .bottom-left {
    left: 0;
}
.top-right, .bottom-right {
    right: 0;
}
</style>
