
import { PropType } from 'vue'
import { Options, Vue } from 'vue-class-component'

export enum EPosition {
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right'
}
export type Position = EPosition
export interface Content {
    title?: string,
    text?: string,
    position?: EPosition
}

// eslint-disable-next-line no-use-before-define
@Options<ImageDisplayer>({
  props: {
    index: Number,
    current: Number,
    image: String,
    content: Array as PropType<Content[]>
  },
  watch: {
    current (current: number) {
      this.visible = current === this.index
      if (this.visible) this.selected = Math.round(Math.random() * (this.content.length - 1))
    },
    content () {
      this.selected = Math.round(Math.random() * (this.content.length - 1))
    }
  }
})
export default class ImageDisplayer extends Vue {
    visible = false
    index!: number

    image = null
    content: Content[] = []

    selected = 0
    get title (): string {
      return this.content[this.selected]?.title || ''
    }

    get text (): string {
      return this.content[this.selected]?.text || ''
    }

    get position (): Position {
      return this.content[this.selected]?.position || EPosition.TOP_LEFT
    }
}
