<template>
    <image-displayer v-for="(entry, index) in values"
        :key="index"
        :index="index"
        :current="current"
        :content="entry.content"
        :image="entry.image"
    />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ImageDisplayer from './components/ImageDisplayer.vue'

@Options({
  components: {
    ImageDisplayer
  }
})
export default class App extends Vue {
    values = []

    current = -1
    mounted (): void {
      fetch('https://gist.githubusercontent.com/jpsl00/2ee99ac95c19dcd10f5d58f9a68938e2/raw/config.json')
        .then(response => response.text())
        .then(response => {
          this.values = JSON.parse(response)

          // Should hopefully preload the images
          this.values.forEach((entry: { image:string }) => {
            new Image(0, 0).src = entry.image
          })

          setInterval(() => {
            console.log('changing')
            this.change()
          }, 10000)
          setTimeout(() => {
            this.change()
          }, 1)
        })
    }

    change (): void {
      this.current = this.random()
    }

    random (): number {
      const random = Math.round(Math.random() * this.values.length)

      if (random === this.current && this.values.length) {
        return this.random()
      }
      return random
    }
}
</script>

<style lang="scss">
@import url('~@/assets/styles/app.scss');
</style>
